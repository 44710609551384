import React, { useState } from 'react';
import { Switch, Typography } from '@mui/material';

export default function TemplateSwitcher() {
  const [isNewTemplate, setIsNewTemplate] = useState(false);

  const handleToggle = () => {
    setIsNewTemplate((prev) => !prev);
  };

  return (
    <>
      <Typography variant='body1'>
        {isNewTemplate ? 'New Template View' : 'Classic View'}
      </Typography>
      <Switch
        checked={isNewTemplate}
        onChange={handleToggle}
        color='primary'
        inputProps={{ 'aria-label': 'View toggle switch' }}
      />
    </>
  );
}
