import useSession from '@/utils/hooks/useSession';
import moment from 'moment-timezone';

// Get the current time as either a string or moment.js object.
export function getCurrentTime(timezone = 'GMT', raw = false) {
  const time = moment().tz(timezone);
  return raw ? time : time.format();
}

export function getTimezones(config) {
  let names = moment.tz.names();
  if (config?.codes) {
    names = names.map((z) => moment.tz(z).zoneAbbr());
  }
  return [...new Set(names)];
}

export function getStandardFormat(format, timezone = false) {
  const formats = {
    iso: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    date: 'DD MMM YYYY',
    isoDate: 'YYYY-MM-DD',
    time: 'HH:mm',
    dateTime: 'DD MMM YYYY HH:mm',
    dateTimeWithSeconds: 'DD MMM YYYY HH:mm:ss',
    dateTimeWithMilliSeconds: 'DD MMM YYYY HH:mm:ss.SSS',
  };
  const selectedFormat = formats[format];

  if (!selectedFormat)
    throw new Error(`Invalid format passed to datetime module: ${format}`);

  if (timezone) {
    return `${selectedFormat} z`;
  }
  return selectedFormat;
}

// Hook to allow components to access the renderTime function.
export function useRenderTime() {
  const { user } = useSession();
  return {
    renderTime: (timeNoOffset, format, args) =>
      renderTime(timeNoOffset, format, user, args),
  };
}

// Render time in the given format.
export function renderTime(timeNoOffset, format, user, args = {}) {
  const { nullValue, timezoneOverride, displayTimezone } = args;

  // Accepts standard formats or custom formats.
  let formatToUse;
  try {
    formatToUse = getStandardFormat(format, displayTimezone);
  } catch {
    formatToUse = format;
  }
  // Defaults to users Preferred Timezone, which defaults to 'America/New_York'.
  const timezone = timezoneOverride || user?.timezone || 'America/New_York';
  // TimeNoOffset must be a zero-offset time string (ends with 'Z') or a UTC time string.
  const time = moment(timeNoOffset).tz(timezone);
  if (!time.isValid() || timeNoOffset === undefined)
    return nullValue || 'Invalid Date';
  return time.format(formatToUse);
}

// Given 2 date strings or Date objects, return the difference in milliseconds.
export function compareTimes(a, b) {
  return moment(b) - moment(a);
}
